<!--
 * @Author: 刘格优
 * @Date: 2020-06-16 14:46:26
 * @LastEditors: 刘格优
 * @LastEditTime: 2020-06-29 16:09:17
--> 
<!--  -->
<template>
  <div>
    <div v-show="!isNull">
      <md-scroll-view class="scroll"
                      ref="scrollView"
                      auto-reflow
                      :scrolling-x="false"
                      @end-reached="loadMorechange">
        <img src="@/assets/xxb/kstop.jpg"
             alt="">
        <div class="scroll">
          <div class="poster"
               v-for="(item, index) in list"
               :key="index"
               @click="todo(item)">
            <div class="itembox">
              <div class="left">
                <img src="@/assets/xxb/gb.png"
                     alt=""
                     class="gbimg">
                <span class="title">{{item.title}}</span>
              </div>
              <div class="right">
                <span :class="item.score ?'score':'line'">{{item.score?item.score:'--'}}</span>
                <md-icon name="arrow-right"
                         class="icondown"
                         size="xs"
                         color="#CCCCCC"></md-icon>

              </div>
            </div>
            <div class="kg">

            </div>
          </div>
        </div>
        <md-scroll-view-more slot="more"
                             :is-finished="listFinished" />
      </md-scroll-view>
    </div>
    <div class="nodata"
         v-show="isNull">
      <img class="kongimg"
           src="@/assets/image/null.png"
           alt="" />
    </div>
  </div>
</template>

<script>
import { paperlist, getresult } from '@/api/xxb/index'
import { getStorage } from '@/lib/util'
import loadMorechange from '@/mixins/loadMorechange'
export default {
  mixins: [loadMorechange],
  data () {
    return {
      list: [],
      user: {},
      listpageNo: 1,
      listsize: 9,
      listtotal: 0,
      listtotalPage: 0,
      loadflag: false,
      listFinished: false,
      isNull: true,
    };
  },
  created () {
    this.user = getStorage('u_s', {})
    this.getData()
  },
  mounted () {
    document.querySelector('.scroll').style.height = `${document.body.clientHeight - 160}px`
  },
  activated () {
    this.list = []
    this.getData()
  },
  methods: {
    getData () {
      let data = {
        empno: this.user.empno,
        page: this.listpageNo,
        size: this.listsize
      }
      paperlist(data).then((res) => {
        this.list = this.list.length == 0 ? res.data.data.rows : this.list.concat(res.data.data.rows)
        if (this.list.length == 0) {
          this.isNull = true
        } else {
          this.isNull = false
        }
        this.listtotal = res.data.data.total
        this.listtotalPage = res.data.data.totalpage
        if (this.listtotalPage <= this.listpageNo) {
          this.listFinished = true
        } else {
          this.listFinished = false
          this.$refs.scrollView.finishLoadMore()
        }
        this.loadflag = true
      })
    },
    todo (item) {

      if (item.score) {
        this.$router.push({
          path: `/testrecode?papercode=${item.papercode}`,
        })
      } else {
        this.$router.push({
          path: `/testdetail?papercode=${item.papercode}`,
        })
      }

    }
  }
}

</script>
<style scoped lang="stylus">
.scroll {
  overflow-y: hidden;
}

.itembox {
  padding: 0.42rem 0.4rem;
  display: flex;
  justify-content: space-between;

  .left {
    display: flex;
    align-items: center;
  }

  .right {
    display: flex;
    align-items: center;
  }

  .gbimg {
    width: 0.42rem;
    height: auto;
  }

  .title {
    color: #383838;
    font-size: 0.4rem;
    padding: 0 0.4rem;
  }

  .score {
    float: right;
    color: #F25E5E;
    font-size: 0.43rem;
  }

  .icondown {
    color: #CCCCCC;
    padding-left: 0.3rem;
    font-weight: bolder;
    font-size: 0.3rem;
  }

  .line {
    color: #CCCCCC;
  }
}

.kg {
  width: 100%;
  height: 0.2rem;
  background-color: #F5F5F5;
}

.nodata {
  padding-top: 30%;
  text-align: center;

  .kongimg {
    width: 300px;
    height: auto;
  }
}
</style>
<!--
 * @Author: 刘格优
 * @Date: 2020-06-19 14:16:18
 * @LastEditors: 刘格优
 * @LastEditTime: 2020-07-28 14:27:53
-->
<template>
  <div>
    <div class="fixedimg"
         @click="popshow">
      <img src="@/assets/xxb/wd3.png"
           alt="" />
      我也想问
    </div>
    <div v-show="!isNull"
         class="scrolltopc">
      <md-scroll-view class="scroll"
                      ref="scrollView"
                      auto-reflow
                      :scrolling-x="false"
                      :is-prevent="false"
                      @end-reached="loadMorechange">
        <div class="alibox">
          <ul class="topul">
            <li v-for="(item, index) in toplist"
                :key="index">
              <div class="evetop"
                   @click="todetail(item)">
                <img src="@/assets/xxb/wd4.png"
                     alt="" />
                <div class="neirong">
                  <div class="pbox">
                    <p class="one">{{ item.question }}</p>
                    <p class="two">{{ item.questiondesc }}</p>
                  </div>
                  <div :style="{
                      'background-image': 'url(' + ossurl + item.imgurl + ')'
                    }"
                       class="img"></div>
                </div>
                <div class="right">
                  <img src="@/assets/xxb/wd5.png"
                       alt="" />
                  <span>{{ item.answernum }}</span>
                </div>
              </div>
            </li>
          </ul>
        </div>
        <div class="all">
          <div class="poster"
               v-for="(item, index) in list"
               :key="index"
               @click="todetail(item)">
            <div class="itembox">
              <div class="top">
                <img src="@/assets/xxb/wd6.png"
                     alt="" />
                <div class="side">
                  <p>{{
                  item.question.length > 16
                    ? item.question.substr(0, 15) + "..."
                    : item.question
                }}</p>
                </div>

              </div>
              <p class="desc">{{item.questiondesc.length > 16
                    ? item.questiondesc.substr(0, 15) + "..."
                    : item.questiondesc}}</p>
              <div class="middle"
                   v-if="item.answerphone">
                <img :src="item.headimg"
                     alt=""
                     v-if="item.answerempno != 'ADMIN'"
                     class="noadmin">
                <div v-else
                     class="hltx">
                  <img src="@/assets/xxb/gft.png"
                       alt=""
                       class="admin">
                  <span> 汇立天下</span>

                  <img src="@/assets/xxb/gfimg.png"
                       alt=""
                       class="right">
                </div>

                <span class="blue"
                      v-if="item.answerempno != 'ADMIN'">{{ item.answerphone }}</span>
                <span class="huise">回答了</span>
              </div>
              <div class="words">
                {{ item.answer }}
              </div>
              <div class="bot">
                <div class="left">
                  {{ item.showtime }}
                </div>
                <div class="right">
                  <img src="@/assets/xxb/wd5.png"
                       alt="" />
                  <span>{{ item.answernum }}</span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <md-scroll-view-more slot="more"
                             :is-finished="listFinished" />
      </md-scroll-view>
    </div>
    <div class="nodata"
         v-show="isNull">
      <img class="kongimg"
           src="@/assets/image/null.png"
           alt="" />
    </div>
    <md-popup v-model="isPopupShow"
              position="bottom">
      <md-popup-title-bar title="提出问题"
                          describe=""
                          ok-text="发送"
                          cancel-text="关闭"
                          large-radius
                          @confirm="sendwt()"
                          @cancel="hidePopUp()"></md-popup-title-bar>
      <div class="md-example-popup md-example-popup-bottom">
        <md-field>
          <md-textarea-item title="问题："
                            class="example"
                            v-model="formdata.question"
                            placeholder="请输入问题"
                            :max-height="150"
                            name="question"
                            v-validate="'required'"
                            :error="errors.first('question')"
                            @keyup="wtkeyup"
                            @keydown="wtkeydown" />
          <md-textarea-item title="问题描述："
                            class="example"
                            v-model="formdata.questiondesc"
                            placeholder="请输入问题描述"
                            :max-height="150"
                            name="questiondesc"
                            v-validate="'required'"
                            :error="errors.first('questiondesc')"
                            @keyup="mskeyup"
                            @keydown="mskeydown" />
        </md-field>
      </div>
    </md-popup>
  </div>
</template>

<script>
import { wdlist, addQuestion } from "@/api/xxb/index";
import { getStorage } from "@/lib/util";
import loadMorechange from "@/mixins/loadMorechange";
import { TextareaItem } from 'mand-mobile'
const debounce = function (fn, delay) {
  let timer
  return function () {
    const context = this
    timer && clearTimeout(timer)

    timer = setTimeout(function () {
      fn.apply(context, arguments)
    }, delay)
  }
}
export default {
  mixins: [loadMorechange],
  data () {
    return {
      list: [],
      toplist: [],
      user: {},
      listpageNo: 1,
      listsize: 9,
      listtotal: 0,
      listtotalPage: 0,
      loadflag: false,
      listFinished: false,
      isNull: true,
      ossurl: "",
      isPopupShow: false,
      formdata: {
        question: "",
        questiondesc: ""
      },
      scrollTop: 0,
    };
  },
  created () {
    this.user = getStorage("u_s", {});
    this.ossurl = getStorage("ossurl", "");
    this.getData();

  },
  mounted () {
    document.querySelector(".scroll").style.height = `${document.body
      .clientHeight - 100}px`;
    // this.$refs.scrollView.finishLoadMore()
    // this.$refs.scrollView.reflowScroller()
    // this.$refs.scrollView.scrollTo(0, 200, true)
  },
  activated () {
    // this.list = []
    // this.getData()

    // this.scrollTop = this.scrollTop + 'px'
    // console.log(this.scrollTop)
    // this.$refs.scroll_bar
    //   ? (this.$refs.scroll_bar.scrollTop = this.scrollTop)
    //   : "";
    // console.log(this.$refs.scrollView.scrollTop)
    // this.scrollTop = 0
    // console.log(this.scrollTop)
    // this.$refs.scrollView.scrollTo(0, this.scrollTop, true)
    // this.$refs.scrollView.scrollTo(0, this.scrollTop, true)
    // window.scrollTop(this.scrollTop)
    // $('.scrolltopc').scrollTop(this.scrollTop)
    // window.scrollTo({
    //   top: this.scrollTop,
    //   behavior: "smooth"
    // });
    // console.log(this.$refs.scrollView)
    // debounce(() => {
    //   const offsetTop = this.scrollTop
    //   this.$refs.scrollView.finishLoadMore()
    //   this.$refs.scrollView.scrollTo(0, 1000, true)
    // }, 100)()
  },
  methods: {
    getData () {
      let data = {
        // empno: this.user.empno,
        page: this.listpageNo,
        size: this.listsize,
        questioninfo: "",
        empno: ""
      };
      wdlist(data).then(res => {
        this.toplist = res.data.data.top;
        this.list =
          this.list.length == 0
            ? res.data.data.rows
            : this.list.concat(res.data.data.rows);
        if (this.list.length == 0 && this.toplist.length == 0) {
          this.isNull = true;
        } else {
          this.isNull = false;
        }
        this.listtotal = res.data.data.total;
        this.listtotalPage = res.data.data.totalpage;
        if (this.listtotalPage <= this.listpageNo) {
          this.listFinished = true;
        } else {
          this.listFinished = false;
          this.$refs.scrollView.finishLoadMore();
        }
        this.loadflag = true;
      });
    },
    popshow () {
      this.formdata = {
        question: "",
        questiondesc: ""
      }
      this.$validator.reset();

      this.isPopupShow = true;
    },
    hidePopUp () {
      this.isPopupShow = false;
    },
    sendwt () {
      this.$validator.validateAll().then(valid => {
        if (valid) {
          let data = {
            ...this.formdata,
            flag: "add",
            isofficial: "1"
          };
          addQuestion(data).then(res => {
            this.isPopupShow = false;
            this.listpageNo = 1;
            this.list = [];
            this.getData();
          });
        }
      });
    },
    todetail (item) {
      this.scrollTop = this.$refs.scrollView.scrollY;
      console.log(this.$refs.scrollView)
      console.log(this.$refs.scrollView.scrollY)
      console.log(this.scrollTop)
      // return
      this.$router.push({
        path: `/answerdetail?questioncode=${item.questioncode}`
      });
    },
    wtkeyup (name, event) {
      // console.log('11111111111')
      // console.log(name)
      // console.log(event)
      this.isPopupShow = true
    },
    mskeyup (name, event) {
      // console.log('2222222222')
      // console.log(name)
      // console.log(event)
      this.isPopupShow = true
    },
    mskeydown (name, event) {
      // console.log('33333333333')
      // console.log(name)
      // console.log(event)
      this.isPopupShow = true
    },
    wtkeydown (name, event) {
      // console.log('4444444444444')
      // console.log(name)
      // console.log(event)
      this.isPopupShow = true
    }
  }
};
</script>
<style scoped lang="stylus">
.all {
  padding: 0 0.5rem 0.5rem;
}

.fixedimg {
  background-image: linear-gradient(to right, #FF8860, #F2682A);
  width: 2.6rem;
  height: 1rem;
  border-radius: 1rem;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  right: 5%;
  bottom: 18%;
  z-index: 5;
  font-size: 0.39rem;
  font-family: PingFang SC;
  color: #ffffff;

  img {
    width: 0.54rem;
    height: auto;
    margin-right: 0.1rem;
  }
}

.alibox {
  width: 100%;
  overflow-x: hidden;
}

.topul::-webkit-scrollbar {
  display: none; /* Chrome Safari */
}

.topul {
  display: -webkit-box;
  overflow-x: auto;
  padding: 0.5rem 0.5rem 0.3rem;

  li {
    width: 60%;
    padding: 0 0.1rem;

    .evetop {
      // border-right: 1px solid #f1f1f1;
      // border-bottom: 0.1rem solid #f1f1f1;
      // border-radius: 0.12rem;
      border-radius: 0.12rem;
      box-shadow: 0rem 0.07rem 0.05rem #E5E5E5;

      .right {
        color: #767676;
        font-size: 0.35rem;
        display: flex;
        align-items: center;
        justify-content: flex-end;
        padding: 0.2rem;

        img {
          width: 0.37rem;
          height: auto;
          margin-right: 0.15rem;
        }

        span {
          color: #C4C4C4;
          font-size: 0.33rem;
        }
      }

      img {
        width: 0.4rem;
        margin-left: 0.2rem;
      }

      .neirong {
        display: flex;
        justify-content: space-between;
        padding: 0 0.2rem;

        .pbox {
          width: 70%;

          p {
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
          }

          .one {
            color: #383838;
            font-size: 0.39rem;
            font-family: PingFang SC;
            font-weight: bold;
            line-height: 0.6rem;
          }

          .two {
            color: #8D9095;
            font-size: 0.33rem;
            font-family: PingFang SC;
            font-weight: 500;
            line-height: 0.6rem;
          }
        }

        .img {
          border-radius: 25px;
          width: 1.1rem;
          height: 1.1rem;
          background-size: cover;
          background-position: center;
        }
      }
    }
  }
}

.poster {
  padding: 0.4rem 0;
  border-bottom: 1px solid #f1f1f1;

  .itembox {
    .top {
      display: flex;
      align-items: center;

      img {
        width: 0.64rem;
        height: auto;
      }

      .side {
        padding-left: 0.2rem;

        p {
          color: #383838;
          font-size: 0.43rem;
          font-family: PingFang SC;
          font-weight: bold;
          line-height: 0.8rem;
        }
      }
    }

    .desc {
      color: #8d9095;
      font-size: 0.39rem;
      font-family: PingFang SC;
      font-weight: 500;
      line-height: 0.6rem;
      padding-left: 0.8rem;
    }

    .middle {
      padding: 0.2rem 0;
      display: flex;
      align-items: center;

      .blue {
        color: #4EA6E6;
        font-size: 0.38rem;
        padding-right: 0.2rem;
        padding-left: 0.2rem;
      }

      .huise {
        color: #999999;
        font-size: 0.4rem;
      }

      .noadmin {
        width: 0.54rem;
        border-radius: 50%;
      }
    }

    .hltx {
      display: flex;
      align-items: center;

      .admin {
        width: 0.54rem;
        border-radius: 50%;
      }

      .right {
        width: 0.8rem;
        padding-right: 0.2rem;
      }

      span {
        color: #4EA6E6;
        font-size: 0.38rem;
        padding-left: 0.2rem;
      }
    }

    .words {
      color: #383838;
      font-size: 0.39rem;
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 3;
      -webkit-box-orient: vertical;
      line-height: 0.7rem;
      margin-bottom: 0.15rem;
      padding-left: 0.8rem;
    }

    .bot {
      display: flex;
      justify-content: space-between;

      .left {
        color: #AAAAAA;
        font-size: 0.31rem;
      }

      .right {
        display: flex;
        align-items: center;

        img {
          width: 0.4rem;
          height: auto;
          margin-right: 0.15rem;
        }

        span {
          color: #767676;
          font-size: 0.33rem;
        }
      }
    }
  }
}

.nodata {
  padding-top: 30%;
  text-align: center;

  .kongimg {
    width: 300px;
    height: auto;
  }
}

/deep/ .md-textarea-item__textarea {
  font-size: 0.38rem !important;
}
</style>

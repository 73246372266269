<template>
  <div>
    <div class="tabbar">
      <p class="tab"
         :class="current == '1'?'orange':''"
         @click="current = '1'">走进汇立</p>
      <p class="tab"
         :class="current == '2'?'orange':''"
         @click="current = '2'">新人基础</p>
      <p class="tab"
         :class="current == '3'?'orange':''"
         @click="current = '3'">进阶修炼</p>
      <p class="tab"
         :class="current == '4'?'orange':''"
         @click="current = '4'">考试中心</p>
    </div>
    <div class="lesslist"
         :style="`height:${height}px;overflow-y:auto;padding-bottom: .5rem;`">
      <incomehl v-if="current=='1'"
                ref="incomehl"></incomehl>
      <newbase v-if="current=='2'"
               ref="newbase"></newbase>
      <jjxl v-if="current=='3'"></jjxl>
      <!-- <img src="@/assets/nh/jsz.jpg"
           alt=""
           v-if="current == 4"
           class="jsz"> -->
      <test v-if="current=='4'">

      </test>

    </div>
  </div>
</template>
<script>
import incomehl from "./component/incomehl";
import newbase from "./component/newbase";
import jjxl from './component/jjxl';
import test from './component/test'
export default {
  components: {
    incomehl,
    newbase,
    jjxl,
    test
  },
  data () {
    return {
      current: '1',
      height: ''
    };
  },
  mounted () {
    this.$nextTick(() => {
      this.height = document.querySelector('.c-box').clientHeight - document.querySelector('.topbar').clientHeight - document.querySelector('.tabbar').clientHeight - document.querySelector('.tab_bottom').clientHeight
    })

  },
  methods: {
    topercenter () {
      this.$router.push("/percenter");
    }
  }
}
</script>
<style lang="stylus" scoped>
.tabbar {
  display: flex;
  justify-content: space-between;
  padding: 20px 30px;

  .tab {
    width: 24%;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 32px;
    height: 66px;
    border-radius: 50px;
    color: #9B9B9B;
    border: 0.02rem solid #EFEFEF;
  }

  .orange {
    background: #FFF5F5;
    color: color-primary;
    border: 1px solid #FFF5F5;
  }
}

.jsz {
  height: auto;
  margin-top: 30%;
}
</style>
<!--
 * @Author: 王鹏
 * @Date: 2020-03-13 14:26:36
 * @LastEditors: 刘格优
 * @LastEditTime: 2020-05-20 10:16:45
 -->
<template>
  <div class="c-box">
    <div class="topbar">
      <img src="@/assets/xxb/per.png"
           alt
           class="arrow"
           @click="topercenter" />
      <md-tab-bar :ink-length="10"
                  v-model="current"
                  :items="items"
                  style="backgroundColor:#ffffff"></md-tab-bar>
      <!-- <img src="@/assets/xxb/search.png"
           alt
           class="arrow1"
           @click="searchdata" /> -->
    </div>
    <div class="content">
      <tuijian v-if="current == 0" />
      <study v-if="current == 1"
             ref='study' />
      <!-- <img src="@/assets/nh/jsz.jpg"
           alt=""
           v-if="current == 3"
           class="jsz"> -->
      <answer v-if="current == 3"></answer>
    </div>
    <div>
      <nhwx></nhwx>
    </div>
  </div>
</template>
<script>
import nhwx from "@/views/basic/home/component/nhwx";
import { Tabs, TabPane } from "mand-mobile";
import tuijian from "./xuexi/tuijian";
import study from "./xuexi/study";
import answer from "./xuexi/answer";
export default {
  components: {
    nhwx,
    [Tabs.name]: Tabs,
    [TabPane.name]: TabPane,
    tuijian,
    study,
    answer
  },
  data () {
    return {
      current: 0,
      items: [
        { name: 0, label: "推荐" },
        { name: 1, label: "学习" },
        // { name: 2, label: "讲坛" },
        { name: 3, label: "问答" }
      ]
    };
  },
  activated () {
    if (this.current == 1) {
      switch (this.$refs.study.current) {
        case '1':
          this.$refs.study.$refs.incomehl.getData()
          break;
        case '2':
          this.$refs.study.$refs.newbase.getData()
          break
        default:
          break;
      }
    }
  },
  created () {
    if (this.$route.query.current) this.current = this.$route.query.current;
    if (this.current == 1) {
      switch (this.$refs.study.current) {
        case '1':
          this.$refs.study.$refs.incomehl.getData()
          break;
        case '2':
          this.$refs.study.$refs.newbase.getData()
          break
        default:
          break;
      }
    }
  },
  methods: {
    topercenter () {
      this.$router.push("/percenter");
    },
    searchdata () {
      this.$router.push({
        path: "/searchdata",
        // query:{
        //   page: this.current
        // }
      });
    }
  }
};
</script>
<style lang="stylus" scoped>
.c-box {
  height: 100%;
  overflow-y: auto;
  padding-bottom: 1rem;
  background-color: #ffffff;
}

.topbar {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-left: 0.35rem;
  margin-right: 0.35rem;

  .md-tab-bar {
    width: calc(100vw - 1.26rem) !important;
    padding: 0;

    /deep/.md-tab-bar-item {
      font-size: 0.39rem;
    }
  }

  /deep/.is-active {
    font-size: 0.46rem !important;
  }

  .arrow {
    width: 0.63rem;
    height: 0.63rem;
  }

  .arrow1 {
    width: 0.39rem;
    height: 0.48rem;
  }
}

.content {
  border-top: 1px solid rgb(239, 239, 239);
  padding-bottom: 0.3rem;
}

.jsz {
  height: auto;
  margin-top: 30%;
}

/deep/ .md-tab-bar-item {
  color: #383838;
}

/deep/ .is-active {
  color: #FB6821;
}
</style>

<!--
 * @Author: 刘格优
 * @Date: 2020-04-26 09:37:46
 * @LastEditors: 刘格优
 * @LastEditTime: 2020-04-27 19:26:42
 -->
<template>
  <div>
    <div class="nodata"
         v-show="isNull">
      <img class="kongimg"
           src="@/assets/image/null.png"
           alt="" />
    </div>
    <div v-for="(item,index) in lists"
         :key="index"
         class="ali"
         v-show="!isNull">
      <div class="card"
           @click="getarticle(item)">
        <div class="item-media">
          <div class="imgbox">
            <div class="img"
                 :style="{
                      'background-image': 'url(' + ossurl + item.showhttp + ')'
                    }">
              <img src="@/assets/xxb/yd.png"
                   alt="">
            </div>
          </div>
        </div>
        <div class="item-inner">
          <div v-if="item.wztitle && item.wztitle.length > 26">
            <div class="Title"
                 v-html="item.wztitle.slice(0, 26) + '...'"></div>
          </div>
          <div v-else>
            <div class="Title"
                 v-html="item.wztitle"></div>
          </div>
          <div class="bottom">
            <span class="left">
              <img src="@/assets/xxb/ydnum.png"
                   alt="">
              {{item.fclick?item.fclick:item.tclick }}已学
            </span>
            <span class="right">
              <img src="@/assets/xxb/ydtime.png"
                   alt="">
              时长
              {{item.duration}}
            </span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { getwzbycode } from '@/api/xxb/index'
import { getStorage } from '@/lib/util'
export default {
  data () {
    return {
      lists: [],
      ossurl: '',
      isNull: false
    };
  },
  created () {
    this.ossurl = getStorage('ossurl', '')
    this.getData()
  },
  methods: {
    getData () {
      getwzbycode({ flcode: 'ZJHL' }).then((res) => {
        this.lists = res.data.data.wzlist
        if (this.lists.length == 0) {
          this.isNull = true
        } else {
          this.isNull = false
        }
      })
    },
    getarticle (item) {
      this.$router.push({
        path: '/wzdetails',
        query: {
          wzpath: item.wzinfo,
          wzcode: item.wzcode,
          wztitle: item.wztitle
        }
      })
    }
  }
}

</script>
<style scoped lang="stylus">
.ali {
  position: relative;
  border-bottom: 1px #efefef solid;

  .card {
    background: #fff;
    font-size: 0.7rem;
    display: flex;
    padding: 30px 4% 30px;
    position: relative;

    .item-media {
      position: relative;
      width: 33%;

      .imgbox {
        width: 100%;
        height: 100%;

        .img {
          position: relative;
          display: block;
          width: 100%;
          height: 100%;
          border-radius: 25px;
          background-size: cover;
          background-position: center;

          img {
            width: 1rem;
            height: auto;
            position: absolute;
            left: 0.2rem;
            top: 0.2rem;
          }
        }
      }
    }

    .item-inner {
      margin-left: 0.5rem;
      width: 60%;

      .Title {
        font-size: 0.39rem;
        color: #383838;
        text-align: left;
        height: 1.8rem;
      }

      .bottom {
        display: flex;
        align-items: center; /* 定义body的元素垂直居中 */

        span {
          color: #B2AFB2;
          font-size: 0.3rem;

          img {
            width: 0.3rem;
          }
        }

        .right {
          padding-left: 0.3rem;
        }
      }
    }
  }
}

.nodata {
  padding-top: 30%;
  text-align: center;

  .kongimg {
    width: 300px;
    height: auto;
  }
}
</style>
<template>
  <div>
    <div class="banner"
         v-if="bannerlists.length > 0">
      <md-swiper ref="swiper"
                 :is-prevent="false">
        <md-swiper-item :key="$index"
                        v-for="(item, $index) in bannerlists">
          <img :src="getStorage('ossurl', '') + item.bannerhttp"
               alt
               class="banner_item"
               @click="towzdetails(item)" />
        </md-swiper-item>
      </md-swiper>
    </div>
    <div class="hotpoint"
         v-for="item in wzfllist"
         :key="item.flcode">
      <p>
        <!-- <img src="@/assets/xxb/hotpoint.png" alt /> -->
        {{item.flname}}
      </p>
      <span class="border"></span>
      <div class="list">
        <div class="list_item"
             v-for="ele in item.wzlist"
             :key="ele.wzcode"
             @click="towzdetails(ele)">
          <div class="list_">
            <span class="tag">热门</span>
            <span class="wztitle">{{ele.wztitle  }}</span>
          </div>

          <md-icon name="arrow-right"
                   size="md"></md-icon>
        </div>
      </div>
    </div>
    <!-- <div class="hotpoint1">
      <p>产品解读</p>
      <span class="border"></span>
      <div class="list">
        <div class="list_item" v-for="item in 20" :key="item">
          <div class="list_">
            <img src="@/assets/xxb/jiedu.png" alt />
            <span>渤海嘉乐宝，60岁前1.5倍保费</span>
          </div>
          <md-icon name="arrow-right" size="md"></md-icon>
        </div>
      </div>
    </div> -->
  </div>
</template>
<script>
import { getwzbycode } from '@/api/xxb'
import { getStorage } from '@/lib/util'
export default {
  data () {
    return {
      bannerlists: [],
      wzfllist: [],
      flname: ''
    };
  },
  created () {
    getwzbycode({ flcode: 'TJ' }).then(res => {
      if (res.data.data.children && res.data.data.children.length > 0) {
        this.wzfllist = res.data.data.children.filter(i => i.flcode != 'BANNER' && i.wzlist.length > 0)
        this.bannerlists = res.data.data.children.filter(ele => ele.flcode == 'BANNER')[0].wzlist || []
      }
    })
  },
  methods: {
    towzdetails (row) {
      this.$router.push({
        path: '/wzdetails',
        query: {
          wzcode: row.wzcode,
        }
      })
    }
  },
};
</script>
<style lang="stylus" scoped>
.banner {
  width: 95%;
  margin: 0 auto;
  height: 2.92rem;
  background-color: white;
  border-radius: 12px !important;
  overflow: hidden;
  margin-top: 0.35rem;

  /deep/.md-swiper-item {
    height: 100% !important;
    width: 100% !important;
  }
}

.hotpoint {
  background-color: white;

  // height: 720px;
  .border {
    display: block;
    width: calc(100vw - 0.72rem) !important;
    height: 1px;
    margin-left: 0.36rem;
    margin-top: 0.36rem;
    background: rgb(239, 239, 239);
  }

  p {
    font-size: 0.4rem;
    font-family: PingFang SC;
    font-weight: bold;
    color: rgba(51, 51, 51, 1);
    padding: 38px 38px 0;
  }

  .list {
    padding: 0 0.35rem;
    // height: 5.21rem;
    // overflow-y: scroll;
    margin: 40px auto;

    .list_item {
      height: 100px;
      display: flex;
      margin: 0.3rem 0;
      padding-bottom: 0.3rem;
      justify-content: space-between;
      align-items: center;

      .list_ {
        display: flex;
        align-items: center;
      }

      .wztitle {
        font-size: 0.39rem;
        color: #383838;
        font-weight: 500;
        word-break: break-all;
        text-overflow: ellipsis;
        display: -webkit-box;
        width: 7.2rem;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 2;
        overflow: hidden;
      }

      .tag {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 0.71rem;
        height: 0.41rem;
        border: 1px solid #FB6821;
        border-radius: 8px;
        margin-right: 0.5rem;
        font-size: 0.25rem;
        font-family: PingFang SC;
        font-weight: 500;
        color: rgba(251, 104, 33, 1);
      }

      .md-icon {
        color: #9B9B9B;
      }
    }
  }
}

.hotpoint1 {
  background-color: white;
  margin-top: 38px;
  height: 820px;

  .border {
    display: block;
    width: calc(100vw - 0.72rem) !important;
    height: 1px;
    margin-left: 0.36rem;
    margin-top: 0.36rem;
    background: rgb(239, 239, 239);
  }

  p {
    font-size: 0.4rem;
    font-family: PingFang SC;
    font-weight: bold;
    color: rgba(51, 51, 51, 1);
    padding: 38px 38px 0;
  }

  .list {
    padding: 0 50px;
    // height: 500px;
    // overflow-y: scroll;
    margin: 40px auto;

    .list_item {
      height: 100px;
      display: flex;
      justify-content: space-between;
      align-items: center;

      .list_ {
        display: flex;
        align-items: center;

        img {
          width: 0.37rem;
          height: 0.37rem;
          margin-right: 0.27rem;
        }
      }

      span {
        font-size: 0.39rem;
        font-family: PingFang SC;
        font-weight: 500;
        color: rgba(56, 56, 56, 1);
      }

      .md-icon {
        color: #9B9B9B;
      }
    }
  }
}
</style>